import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { symptoReq, BearerT } from '../utils/auth/utils';
import { generateAuthHeader } from '../bulkUploads/symptoUtils';
import { PatientData } from './bulkUploadParse'
import { PatientFetch } from '../symptoTypes';
import {
  DUP_PATIENT_PHONE, NEW_PATIENT, ATTEMPTING_DUP_PH,
} from '../bulkUploads/logs';
import { PatientCreateResponse } from '../symptoTypes';


export const attemptCreatePatient = async ({
  patientData, auth, updateLogs
}: {
  patientData: {
    firstName: string,
    lastName: string,
    systemPhoneNumber: string | null,
    dob: string,
    mrn: string,
    phone: string | null,
  },
  auth: BearerT,
  updateLogs: (msg: string) => void,
}): Promise<{
  patientTvId: string,
} | null> => {
  const emailPhData = patientData.phone == null
    ? {
      email: `${uuidv4()}-no-phone@symptohealth.com`,
      phoneNumber: null,
      notificationType: ['email'],
    }
    : {
      email: null,
      phoneNumber: patientData.phone,
      notificationType: ['text'],
    };
  const patientPayload = {
    firstName: patientData.firstName,
    lastName: patientData.lastName,
    sex: null,
    systemPhoneNumber: patientData.systemPhoneNumber,
    dob: patientData.dob,
    timeZone: 'America/Los_Angeles',
    profilePicture: null,
    language: 'English',
    notes: null,
    mrn: patientData.mrn,
    campaignIds: null,
    groupId: null,
    ...emailPhData,
  };
  const patient: PatientCreateResponse = (await symptoReq(`/providers/patients`, {
    "body": JSON.stringify(patientPayload),
    "method": "POST",
  }, generateAuthHeader(auth, true)));

  if (patient.type === 'success') {
    updateLogs(NEW_PATIENT);
    return { patientTvId: patient.patientTvId };
  }

  if (patient.type === 'clinic-dups') {
    updateLogs(ATTEMPTING_DUP_PH);
    const newSystemPhoneNumber = patient.suggestedSystemPhoneNumber;
    const updatedPt = await symptoReq(`/providers/patients`, {
      "body": JSON.stringify({
        ...patientPayload,
        systemPhoneNumber: newSystemPhoneNumber,
      }),
      "method": "POST",
    }, generateAuthHeader(auth, true));
    updateLogs(`${DUP_PATIENT_PHONE} MRN: ${patientPayload.mrn}. System Phone Number: ${patient.suggestedSystemPhoneNumber}`);
    return { patientTvId: updatedPt.patientTvId };
  }
  updateLogs('FAILURE - Failed to create patient');
  return null;
}

export const findPatientByMRN = async ({
  patientData, auth
}: {
  patientData: PatientData, auth: BearerT,
}): Promise<{
  patientTvId: string,
} | null> => {
  const { patients }: PatientFetch = await symptoReq(`/providers/patients/cache?searchTerm=${patientData['First Name']} ${patientData['Last Name']}&limit=100&offset=0`, {
    "body": null,
    "method": "GET",
  }, generateAuthHeader(auth));
  // if first name and last  and dob match,  then correct patient
  const matchingMRN = patients.find(({ firstName, lastName, dob }) => (
    firstName.trim().toLowerCase() === patientData['First Name'].trim().toLowerCase()
    && lastName.trim().toLowerCase() === patientData['Last Name'].trim().toLowerCase()
    && moment(dob).format('MM/DD/YYYY') === moment(patientData['DOB']).format('MM/DD/YYYY')
  ));

  if (matchingMRN != null) {
    return { patientTvId: matchingMRN.tvid };
  }

  // if dob and  first name match , then check phone number == correct patient
  const { patients: matchingFirstName }: PatientFetch = await symptoReq(`/providers/patients/cache?searchTerm=${patientData['First Name']}&limit=500&offset=0`, {
    "body": null,
    "method": "GET",
  }, generateAuthHeader(auth));
  const matchingDobFirstNamePhone = matchingFirstName.find(({ firstName, dob, phoneNumber }) => (
    firstName.trim().toLowerCase() === patientData['First Name'].trim().toLowerCase()
    && moment(dob).format('MM/DD/YYYY') === moment(patientData['DOB']).format('MM/DD/YYYY')
    && phoneNumber === patientData['Primary Phone #']
  ));
  if (matchingDobFirstNamePhone != null) {
    return { patientTvId: matchingDobFirstNamePhone.tvid };
  }
  return null;
};
