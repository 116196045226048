import qs from 'qs';
import { symptoReq, BearerT } from '../utils/auth/utils';


export const generateAuthHeader = (auth: BearerT, includeRateToken: boolean = false) => ({
  'Authorization': 'Bearer '+auth.authCode,
  ...(includeRateToken ? {
    'rate-token': auth.rateToken,
  } : {}),
});

const assignNewInstrument = async ({
  authCode,
  patientTvId,
  genericSurveyId,
}: {
  authCode: BearerT,
  patientTvId: string,
  genericSurveyId: string,
}) => {
  const { patientSurveyId } = await symptoReq(`/providers/surveys/${genericSurveyId}/default/${patientTvId}/send/now`, {
    body: JSON.stringify({
    }),
    method: 'POST',
  }, generateAuthHeader(authCode));
  return { patientSurveyId };
}
export const assignNewSurvey = async ({
  authCode,
  patientTvId,
  genericSurveyId,
}: {
  authCode: BearerT
  patientTvId: string;
  genericSurveyId: string;
}): Promise<{ patientSurveyId: string }> => {
  return assignNewInstrument({
    authCode,
    patientTvId,
    genericSurveyId,
  })
};

export const fillEncounterData = async ({
  authCode,
  patientSurveyId,
  encounterData,
  updateLogs,
  patientTvId,
  markAsInvalidOnError,
}: {
  authCode: BearerT;
  encounterData: Array<{
    questionTitle: string;
    value: null | string;
  }>;
  patientSurveyId: string;
  patientTvId: string;
  markAsInvalidOnError: boolean;
  updateLogs: (log: string) => void;
}): Promise<{ didFill: boolean }> => {
  try {
    console.log(`Filling encounter data for ${patientSurveyId} - ${JSON.stringify(encounterData)}}`)
    await symptoReq(`/providers/${patientSurveyId}/encounters/response`, {
      body: JSON.stringify(encounterData),
      method: 'POST',
    }, generateAuthHeader(authCode));
    updateLogs(`UPDATE PATIENT - Filled encounter data for ${patientSurveyId} - ${JSON.stringify(encounterData)}}`)
    return { didFill: true };
  } catch (e) {
    updateLogs(`FAILURE - ${e}`)
    updateLogs(`FAILURE: Error filling encounter data for ${patientSurveyId} - ${JSON.stringify(encounterData)}}`)
    alert(e);
    alert(JSON.stringify(encounterData))
    if (markAsInvalidOnError) {
      await symptoReq(`/providers/patientSurvey/${patientSurveyId}/tags`, {
        "body": JSON.stringify({
          patientTvId,
          updatedTags: ['Invalid / Accidental Encounter'],
        }),
        "method": "POST",
      }, generateAuthHeader(authCode));
      alert('Encounter marked as invalid');
    }
    return { didFill: false };
  }
};

export const sendMessageToPatient = async (message: string, patientTvId: string, authCode: BearerT) => {
  await symptoReq(`/providers/patients/${patientTvId}/genericsurvey`, {
    body: JSON.stringify({
      survey: {
        duration: 24,
        defaultTags: null,
        messageData: {
          creation: {
            text: message,
            email: null,
          },
          reminder: {
            text: null,
            email: null,
          },
        },
        notifications: {
          daysBeforeDueDate: [],
        },
        startDatePreference: {
          type: 'immediate',
        },
        genericSurveyId: '71086616-b064-4990-87c8-3955cdad6040',
      }
    }),
    method: 'POST',
  }, generateAuthHeader(authCode));
}


export type EncounterDataT = {
  name: string,
  patientSurveyId: string,
  patientId: string,
  surveyTags: null | string[],
  type: 'instrument' | 'encounter',
  previewData: null | undefined | {
    'Encounter #': string,
  },
  startDate: number,
};

const isValidEncounter = (encounter: EncounterDataT) => {
  return encounter.type === 'encounter' && (encounter.surveyTags == null || !encounter.surveyTags.includes('Invalid / Accidental Encounter'));
}

export const fetchExistingEncounters = async (authCode: BearerT, patientTvId: string): Promise<EncounterDataT[]> => {
    // find when last encounter created
    const params = qs.stringify({
      type: 'Date Filter',
      patientTvId,
      startDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).getTime()
    });

  const existingEncounters = (await symptoReq(`/providers/patientSurvey?${params}`, {
    method: 'GET',
  }, generateAuthHeader(authCode)) as EncounterDataT[]);
  const validEncounters = existingEncounters.filter(isValidEncounter);

  return validEncounters;
};
