import pLimit from 'p-limit';
import { BearerT } from '../utils/auth/utils';
import { fetchExistingEncounters } from '../bulkUploads/symptoUtils';
import { PatientData } from './bulkUploadParse';
import { assignNewSurvey, fillEncounterData } from '../bulkUploads/symptoUtils';
import {
  PATIENT_FAILED, SERVICE_INTRO_PATIENT, LAST_ENCOUNTER_30,
  NEW_PATIENT, ENROLL_IN_ENCOUNTER, UPDATING_EXISTING_ENCOUNTER,
} from '../bulkUploads/logs';
import { createOrUpdatePatient } from './patientCreateOrUpdate';
import { generateEncounterData } from './encounterDataGenerate';
import { isServiceIntroEligible } from './isServiceIntroEligible';
import { isAlreadyExistingEncounter } from './isAlreadyExistingEncounter';

const limit = pLimit(10);

const SERVICE_INTRO_SURVEY_ID = 'ebe7eb01-d30f-4cf6-afd4-86c5ddb1e27e';

const createEncounter = async ({
  patientTvId, patientData, sendServiceIntro, auth, updateLogs, isNewPatient,
}: {
  patientTvId: string, patientData: PatientData, sendServiceIntro: boolean, auth: BearerT, isNewPatient: boolean, updateLogs: (log: string) => void
}) => {
  const formattedEncounterData = generateEncounterData({
    patientData,
  });
  const { patientSurveyId } = await assignNewSurvey({
    authCode: auth,
    patientTvId,
    genericSurveyId: '2e8737f9-fccd-45a2-afb4-317cd39acc42',
  });
  updateLogs(isNewPatient ? NEW_PATIENT : ENROLL_IN_ENCOUNTER);

  const { didFill } = await fillEncounterData({
    authCode: auth,
    patientSurveyId,
    patientTvId,
    updateLogs,
    encounterData: formattedEncounterData,
    markAsInvalidOnError: true,
  });
  if (didFill && sendServiceIntro) {
    // now creating service intro
    await assignNewSurvey({
      authCode: auth,
      patientTvId,
      genericSurveyId: SERVICE_INTRO_SURVEY_ID,
    });
    updateLogs(`${SERVICE_INTRO_PATIENT} - ${patientTvId}`);
  }
  return didFill;
};

const updateExistingEncounter = async ({
  patientTvId, patientData, auth, updateLogs, patientSurveyId,
}: {
  patientTvId: string, patientData: PatientData, auth: BearerT, updateLogs: (log: string) => void, patientSurveyId: string
}) => {
  const formattedEncounterData = generateEncounterData({
    patientData,
  });
  const questionTitlesToUpdate = ['Reason for Admission', 'Substance Use History', 'ED Visit Summary', 'VBP Care Metric'];
  const updateOnlyFields = formattedEncounterData.filter(({ questionTitle }) => questionTitlesToUpdate.includes(questionTitle));

  updateLogs(UPDATING_EXISTING_ENCOUNTER);

  const { didFill } = await fillEncounterData({
    authCode: auth,
    patientSurveyId,
    updateLogs,
    patientTvId,
    encounterData: updateOnlyFields,
    // just na update no need to delete encounter
    markAsInvalidOnError: false,
  });
  return didFill;
};


const getPatientEncounterStatus = async ({
  patientTvId, patientData, authToken, updateLogs,
}: {
  patientTvId: string, patientData: PatientData, authToken: BearerT, updateLogs: (log: string) => void
}): Promise<{
  existingEncounterId: string | null,
  sendServiceIntro: boolean,
}> => {
  const existingEncounters = await fetchExistingEncounters(authToken, patientTvId);
  const sendServiceIntro = isServiceIntroEligible(existingEncounters);
  if (sendServiceIntro === false) {
    updateLogs(LAST_ENCOUNTER_30);
  }

  const doesEncounterExist = await isAlreadyExistingEncounter({
    encounterData: existingEncounters,
    patientData,
    authCode: authToken,
  })

  return { existingEncounterId: doesEncounterExist != null ? doesEncounterExist.matchingPatientSurveyId : null, sendServiceIntro };
};

const uploadPatient = async ({
  patientData, authToken, updateLogs, finishPatient, retries = 0
}: {
  patientData: PatientData,
  authToken: BearerT,
  updateLogs: (log: string) => void,
  finishPatient: () => void,
  retries: number,
}): Promise<void> => {
  if (retries > 2) {
    updateLogs(`${PATIENT_FAILED}. Reason: Too many retries`);
    finishPatient();
    return;
  }
  try {
    const data = await createOrUpdatePatient({
      patientData, authToken, updateLogs,
    });
    if (data == null) {
      updateLogs(`${PATIENT_FAILED}. Reason: Patient not found`);
      return;
    }
    const { patientTvId } = data;

    const { existingEncounterId, sendServiceIntro } = await getPatientEncounterStatus({
      patientTvId, patientData, authToken, updateLogs,
    });
    if (existingEncounterId == null) {
      await createEncounter({
        patientTvId, patientData, sendServiceIntro, auth: authToken, updateLogs, isNewPatient: false
      });
    } else if (existingEncounterId != null) {
      // update encounter
      await updateExistingEncounter({
        patientTvId, patientData, auth: authToken, updateLogs, patientSurveyId: existingEncounterId,
      });
    }
    finishPatient();
  } catch (e) {
    updateLogs(`Intermediate Step - Patient failed to update. Retrying... Reason: ${(e as Error).message}...`);
    console.log(e);
    return uploadPatient({
      patientData, authToken, updateLogs, finishPatient, retries: retries + 1,
    });
  }
}

export const uploadSymptoData = async (
  userData: PatientData[],
  authCode: BearerT,
  updateData: (index: number, log: string) => void,
  finishPatient: () => void,
) => {
  await Promise.all(userData.map((patientData, index) => (limit(async () => {
    const updateLogs: (log: string) => void = (log) => {
      updateData(index, log);
    };

    await uploadPatient({
      patientData, authToken: authCode, updateLogs, finishPatient, retries: 0,
    });
  }))));
}

