import moment from 'moment';
import { PatientData } from './bulkUploadParse';
import { BearerT } from '../utils/auth/utils';
import { validatePhNumber } from '../utils/phoneValidate';
import { updatePatient } from './patientUpdate';
import { ATTEMPTING_NEW_PATIENT, PATIENT_UPDATED, NO_PHONE } from '../bulkUploads/logs';
import { attemptCreatePatient } from './symptoUtils';
import { findPatientByMRN } from './symptoUtils';

export const createOrUpdatePatient = async ({
  patientData, authToken, updateLogs,
}: {
  patientData: PatientData,
  authToken: BearerT,
  updateLogs: (log: string) => void,
}): Promise<null | {
  patientTvId: string,
}> => {
  const matchingPatient = await findPatientByMRN({
    patientData, auth: authToken,
  });
  if (matchingPatient != null) {
    updateLogs(PATIENT_UPDATED);
    await updatePatient({
      patientTvId: matchingPatient.patientTvId,
      patientData,
      authToken,
      updateLogs,
    });
    return { patientTvId: matchingPatient.patientTvId };
  }
  updateLogs(ATTEMPTING_NEW_PATIENT);
  const phoneNumber = validatePhNumber(patientData['Primary Phone #'], updateLogs);
  if (phoneNumber == null) {
    updateLogs(NO_PHONE);
  }
  const data = await attemptCreatePatient({
    patientData: {
      firstName: patientData['First Name'],
      systemPhoneNumber: patientData['System Phone Number'],
      lastName: patientData['Last Name'],
      dob: moment(patientData['DOB'], 'MM/DD/YYYY').format('MM/DD/YYYY'),
      mrn: patientData['MRN'],
      phone: phoneNumber,
    },
    auth: authToken,
    updateLogs,
  });
  return data;
}
