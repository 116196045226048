import _ from 'lodash';
import { PatientData } from './bulkUploadParse';

export const getEncounterNumber = (patientData: PatientData) => {
  return patientData['Encounter #'];
};

const IGNORED_CSV_FIELDS = ['url', 'DOB', 'System Phone Number'];
export const generateEncounterData = ({
  patientData,
}: {
  patientData: PatientData,
}): {
  questionTitle: string,
  value: null | string,
}[] => {
  // ignore fields URL and dob

  const allFields = _.toPairs(patientData).map(([questionTitle, questionValue]) => ({
    questionTitle,
    value: questionValue,
  }));
  const withoutNullValues = allFields.filter(({ value }) => value != null && value.trim().length !== 0);
  const withoutIgnoredFields = withoutNullValues.filter(({ questionTitle }) => !IGNORED_CSV_FIELDS.includes(questionTitle));
  return [
    ...withoutIgnoredFields,
    {
      questionTitle: 'Primary Navigator',
      value: 'Unassigned',
    },
  ];
};
