import _ from 'lodash';

const REQUIRED_COLUMNS = [
  'DOB',
  'In Person PCN Encounter',
  'Site Name',
  'Date of ED Visit / Referral',
  'Discharged',
  'Provider / On-Site Coordinator Name',
  'First Name',
  'Last Name',
  'Encounter #',
  'Email',
  'Primary Phone #',
  'Alternate Phone Number',
  'Previous Phone Numbers',
  'Status',
  'Language',
  'Reason for Navigation',
  'Priority Level',
  'Provider Referral Details / Comments',
  'High Utilizer?',
  'Ethnicity',
  'Identification',
  'MRN',
  'Sex Assigned at Birth',
  'Gender',
  'Pregnant',
  'Race',
  'Patient Address',
  'Chief Complaint',
  'Discharge Diagnosis',
  'Primary Care Provider Name',
  'Provider Specialty',
  'Clinic Name',
  'Clinic Ph #',
  'SUD IET Diagnosis Cohorts',
  'ED Visit Summary',
  'Rx Details',
  'Rx Prescribed',
  'Rx Obtained',
  'Insurance ID',
  'Insurance Type / Payor',
  'Insurance Plan',
  'VBP Care Metric',
  'Secondary Insurance/Payor Plan',
  'Secondary Insurance # (ID)',
  'Reason for Admission',
  'Substance Use History',
  'System Phone Number',
];


export type PatientData = {
'DOB': string | null,
  'In Person PCN Encounter': string | null,
  'Site Name': string | null,
  'System Phone Number': string | null,
  'Date of ED Visit / Referral': string | null,
  'Discharged': string | null,
  'Provider / On-Site Coordinator Name': string | null,
  'First Name': string,
  'Last Name': string,
  'Encounter #': string,
  'Email': string | null,
  'Primary Phone #': string,
  'Alternate Phone Number': string | null,
  'Previous Phone Numbers': string | null,
  'Status': string | null,
  'Language': string | null,
  'Reason for Navigation': string | null,
  'Priority Level': string | null,
  'Provider Referral Details / Comments': string | null,
  'High Utilizer?': string | null,
  'Ethnicity': string | null,
  'Identification': string | null,
  'MRN': string,
  'url': string,
  'Sex Assigned at Birth': string | null,
  'Gender': string | null,
  'Pregnant': string | null,
  'Race': string | null,
  'Patient Address': string | null,
  'Chief Complaint': string | null,
  'Discharge Diagnosis': string | null,
  'Primary Care Provider Name': string | null,
  'Provider Specialty': string | null,
  'Clinic Name': string | null,
  'PCP Clinic': string | null,
  'Clinic Ph #': string | null,
  'SUD IET Diagnosis Cohorts': string | null,
  'ED Visit Summary': string | null,
  'Rx Details': string | null,
  'Rx Prescribed': string | null,
  'Rx Obtained': string | null,
  'Insurance ID': string | null,
  'Insurance Type / Payor': string | null,
  'Insurance Plan': string | null,
  'VBP Care Metric': string | null,
  'Secondary Insurance/Payor Plan': string | null,
  'Secondary Insurance # (ID)': string | null,
  'Reason for Admission': string | null,
  'Substance Use History': string | null,
};

const REQUIRED_VALUES: (keyof PatientData)[] = [
  'First Name',
  'Last Name',
  'Primary Phone #',
  'MRN',
  'Date of ED Visit / Referral',
  'Site Name',
  'System Phone Number',
];
export const bulkUploadParse = (data: {
  [key: string]: string,
}[]): {
  data: PatientData[],
} => {
  if (data.some((row) => (
    REQUIRED_COLUMNS.some((col) => (row[col] == null))
  ))) {
    const missingColumns = _.uniq(_.compact(_.flatten(data.map((row) => (
      REQUIRED_COLUMNS.map((col) => (row[col] == null ? col : null))
    )))));
    throw new Error(`Invalid upload. Missing ${missingColumns.join(', ')} for some records`);
  }
  // check for required values
  if (data.some((row) => (
    REQUIRED_VALUES.some((col) => (row[col] == null))
  ))) {
    const missingValues = _.uniq(_.compact(_.flatten(data.map((row) => (
      REQUIRED_VALUES.map((col) => (row[col] == null ? col : null))
    )))));
    throw new Error(`Invalid upload. Missing ${missingValues.join(', ')} for some records`);
  }
  return {
    data: data.map((row) => ((row as any) as PatientData)),
  };
};
